<template>
  <div class="col-lg-12">
    <div class="">
      <div class=" ">
        <div class="col-lg-12">
          <div class="">
            <!--                  <div class="loader loader-backdrop loader-backdrop-clientsRF" v-if="isClientRFLoading"></div>-->
            <div v-if="isClientRFLoading || isClientEventsLoading || isClientRFRemainingDataLoading">
              <Loader-spinner />
            </div>
            <div class="card-header">
              <span class="h4">
                {{ getFormatMac(clientMacAddr) }}
              </span>
              <!--                    <button type="button" class="btn btn-outline-secondary btn-sm float-right" @click="disableEditMode">-->
              <!--                      <i class="fa fa-close"></i>-->
              <!--                    </button>-->
            </div>
            <div class="card-block pb-0">
              <div class="row">
                <div class="col-lg-12">
                  <date-picker
                    class="float-right"
                    v-if="datepickerVisible"
                    v-model="drPickerDateForRF"
                    v-on:input="datePickerChangedDate"
                  ></date-picker>
                </div>
              </div>
              <div class="">
                <div class="col-lg-12">
                  <div
                    class="w-100"
                    v-if="
                      (clientRFData && clientRFData.length > 0) || (clientEventsData && clientEventsData.length > 0)
                    "
                  >
                    <div class="row">
                      <div class="col-lg-12">
                        <highcharts :options="chartData" ref="highcharts"></highcharts>
                        <!--                              <div class="d-flex justify-content-center">-->
                        <!--                                <div class="mr-1 ml-1 events-counters-under-the-chart">-->
                        <!--                                  {{$t('events.clientRFAndEventsNumberOfEventsCaption')}}:-->
                        <!--                                </div>-->
                        <!--                                <div class="mr-1 ml-1 events-counters-under-the-chart">-->
                        <!--                                  {{$t('events.clientRFAndEventsConnectedChartSeries')}}:-->
                        <!--                                  {{eventsCounterClientConnected}}-->
                        <!--                                </div>-->
                        <!--                                <div class="mr-1 ml-1 events-counters-under-the-chart">-->
                        <!--                                  {{$t('events.clientRFAndEventsDisconnectedChartSeries')}}:-->
                        <!--                                  {{eventsCounterClientDisconnected}}-->
                        <!--                                </div>-->
                        <!--                                <div class="mr-1 ml-1 events-counters-under-the-chart">-->
                        <!--                                  {{$t('events.clientRFAndEventsAuthorizationChartSeries')}}:-->
                        <!--                                  {{eventsCounterClientAuthorization}}-->
                        <!--                                </div>-->
                        <!--                                <div class="mr-1 ml-1 events-counters-under-the-chart">-->
                        <!--                                  {{$t('events.clientRFAndEventsDhcp_AckChartSeries')}}:-->
                        <!--                                  {{eventsCounterClientDHCP_ACK}}-->
                        <!--                                </div>-->
                        <!--                              </div>-->
                      </div>
                    </div>

                    <!--                          <div class="row">-->
                    <!--                            <div class = 'col-lg-12 '>-->
                    <!--&lt;!&ndash;                              <div class="row d-flex justify-content-center"><span>{{$t('events.clientRFAndEventsNumberOfEventsCaption')}}:</span></div>&ndash;&gt;-->
                    <!--                              <div class="row d-flex justify-content-center">-->
                    <!--                                <div class="mr-2 ml-2">{{$t('events.clientRFAndEventsNumberOfEventsCaption')}}: </div>-->
                    <!--                                <div class="mr-2 ml-2">{{$t('events.clientRFAndEventsConnectedChartSeries')}}: {{eventsCounterClientConnected}} </div>-->
                    <!--                                <div class="mr-2 ml-2">{{$t('events.clientRFAndEventsDisconnectedChartSeries')}}: {{eventsCounterClientDisconnected}} </div>-->
                    <!--                                <div class="mr-2 ml-2">{{$t('events.clientRFAndEventsAuthorizationChartSeries')}}: {{eventsCounterClientAuthorization}} </div>-->
                    <!--                                <div class="mr-2 ml-2">{{$t('events.clientRFAndEventsDhcp_AckChartSeries')}}: {{eventsCounterClientDHCP_ACK}} </div>-->
                    <!--                              </div>-->

                    <!--                            </div>-->
                    <!--                          </div>-->
                    <div class="row mt-q p-0">
                      <div class="col-lg-6 d-flex justify-content-center">
                        <transition name="fade">
                          <div
                            v-if="this.wifiRateInfoForSelectedPoint !== null"
                            class="client-rf-and-events-under-chart-info"
                          >
                            <div>
                              <h5 class="client-rf-and-events-under-chart-info-header1">
                                {{ $t('events.clientRFAndEventsRFHeader') }}
                              </h5>
                            </div>
                            <div>
                              <b>{{ $t('clientsRF.rx') }}</b>
                              <div>
                                {{ this.wifiRateInfoForSelectedPoint.rx }}
                              </div>
                            </div>
                            <div>
                              <b>{{ $t('clientsRF.tx') }}</b>
                              <div>
                                {{ this.wifiRateInfoForSelectedPoint.tx }}
                              </div>
                            </div>
                            <div v-if="this.wifiRateInfoForSelectedPoint.throughput !== 0">
                              <b>{{ $t('clientsRF.expectedThroughput') }}</b>
                              <div>
                                {{ this.wifiRateInfoForSelectedPoint.throughput }}
                              </div>
                            </div>
                          </div>
                        </transition>
                      </div>
                      <div class="col-lg-6 d-flex justify-content-center">
                        <transition name="fade">
                          <div
                            v-if="eventInfoForSelectedPoint !== null"
                            class="client-rf-and-events-under-chart-info d-flex flex-wrap"
                            :class="{
                              'client-rf-and-events-under-chart-info-if-more-the-two-events':
                                eventInfoForSelectedPoint.length > 1
                            }"
                          >
                            <div
                              :class="{ 'ml-1': eventInfoForSelectedPoint.length > 1 }"
                              v-for="selectedpointevent in eventInfoForSelectedPoint"
                            >
                              <div>
                                <h5 class="client-rf-and-events-under-chart-info-header1">
                                  {{ $t('events.clientRFAndEventsEventHeader') }}
                                </h5>
                              </div>
                              <div>
                                <h6 class="client-rf-and-events-under-chart-info-header2">
                                  {{
                                    eventStatuslWithI18NAsString(selectedpointevent.type.split(`_`).splice(1).join(` `))
                                  }}
                                  {{ $t('events.clientRFAndEventsClientCaption') }}
                                </h6>
                              </div>
                              <div>
                                <h6 class="mt-1 client-rf-and-events-under-chart-info-header2">
                                  {{ $t('events.eventData') }}
                                </h6>
                                <ul class="events__data-list p-0 events-info-ul-in-client-rf-and-events">
                                  <li v-if="selectedpointevent.subject_id">
                                    <span class="events__data-item text">subject ID:</span>
                                    {{ selectedpointevent.subject_id }}
                                  </li>
                                  <li v-if="selectedpointevent.description">
                                    <span class="events__data-item text">description:</span>
                                    {{ selectedpointevent.description }}
                                  </li>
                                  <li
                                    v-for="(value, key) in selectedpointevent.data"
                                    v-if="value && getCorrectKey(key) !== 'commit'"
                                  >
                                    <span class="events__data-item text">{{ getCorrectKey(key) }}:</span>
                                    {{ getCorrectDataValue(value, key) }}
                                  </li>
                                </ul>
                              </div>
                              <!--                                {{eventInfoForSelectedPoint}}-->
                            </div>
                          </div>
                        </transition>
                      </div>
                    </div>
                  </div>
                  <div class="w-100 d-flex justify-content-center" v-else>
                    <div>
                      {{ $t('general.noDataToDisplay') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--                  <div class="actions-buttons">-->
            <!--                    <div>-->
            <!--                      <button type="button" class="btn btn-outline-secondary btn-md" @click="disableEditMode">-->
            <!--                        {{ $t('general.close') }}-->
            <!--                      </button>-->
            <!--                    </div>-->
            <!--                  </div>-->
          </div>
        </div>
      </div>
    </div>

    <!--        </div>-->

    <!--    </div>-->
  </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import VueNotifications from 'vue-notifications';
import commonService from '../services/commonService';
import clientService from '../services/clientService';
import Filters from '../filters';
import defaultTableSettings from '../table-settings';
import helpers from '../helpers';
import DatePicker from './date-picker.vue';

Vue.use(require('vue-moment'));

export default {
  name: 'ClientRFAndEvents',
  props: {
    clientMacAddr: {
      type: String,
      required: true
    }
  },
  components: { DatePicker },
  data() {
    return {
      // selectedEventPointTimestamp: 0,
      clientEventsData: [],
      clientEventsDataSeparatedByEventType: {
        CLIENT_CONNECTED: [],
        CLIENT_DISCONNECTED: [],
        CLIENT_AUTHORIZATION: [],
        DHCP_ACK: []
      },
      currentTab: 0,
      datepickerVisible: true,
      selectedPointTimestamp: 0,
      drPickerDateForRF: {
        startDate: moment().startOf('day').valueOf(),
        endDate: moment().endOf('day').valueOf()
      },
      drPickerDateForRFP: {
        startDate: moment().startOf('day').valueOf(),
        endDate: moment().endOf('day').valueOf()
      },
      clientRFData: [],
      chartSettings: {
        time: {
          useUTC: false
        },
        chart: {
          type: 'area',
          spacingBottom: 1,
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        title: {
          text: this.$t('clientsRF.clientRFChartTitle')
        },
        // subtitle: {
        //   text: this.$t('statistics.pan')
        // },
        xAxis: {
          type: 'datetime',
          crosshair: true
        },
        yAxis: {
          title: {
            text: 'dBm'
          },
          max: 0,
          labels: {
            formatter() {
              return this.value;
            }
          }
        },
        tooltip: {
          // конструирование функции для вывода тулбара (tooltip.formater()) вынесенно в метод
          // this.createChartToolbarFormatterWithLocalization().
          // Поскольку в этой функции нужен контекст графика (например this.points[1].y) и одновременно контекст
          // этого компонента ( например this.$t('clientsRF.signal')). Поэтому в
          // reateChartToolbarFormatterWithLocalization()
          // создается функция с уже переведенными строками.
          shared: true
        },
        legend: {
          // конструирование функции для вывода подписей в легенде на графике (legend.labelFormatter()) вынесенно в метод
          // this.createChartLegendLabelFormatterWithLocalization().
        },
        plotOptions: {
          area: {
            fillOpacity: 1.0,
            threshold: null
          },
          series: {
            // Если разрыв между 2 соседними точками больше чем в gapSize, то график разрывается.
            // Тут подробнее https://api.highcharts.com/highstock/6.0.5/plotOptions.series.gapSize
            gapSize: 600000,
            gapUnit: 'value',
            stickyTracking: false
          }
        },
        credits: {
          enabled: false
        },
        series: [
          // сигнал
          {
            name: this.$t('clientsRF.signal'),
            data: [],
            color: '#5c7df8',
            zIndex: 1,
            events: {
              legendItemClick(e) {
                e.preventDefault();
              }
            },
            point: {
              events: {
                mouseOver: (e) => {
                  this.chartMouseOverPoint(e);
                },
                mouseOut: () => {
                  this.$refs.highcharts.chart.tooltip.hide(10);
                  this.selectedPointTimestamp = 0;
                }
              }
            }
          },
          // шум
          {
            name: this.$t('clientsRF.noise'),
            data: [],
            color: '#f8b6b0',
            zIndex: 2,
            events: {
              legendItemClick(e) {
                e.preventDefault();
              }
            },
            point: {
              events: {
                mouseOver: (e) => {
                  this.chartMouseOverPoint(e);
                },
                mouseOut: () => {
                  this.$refs.highcharts.chart.tooltip.hide(10);
                  this.selectedPointTimestamp = 0;
                }
              }
            }
          },
          // {
          //   name: this.$t('events.clientRFAndEventsChartEventsCaption'),
          //   data: [],
          //   color: 'rgba(146,203,140,0.53)',
          //   type: 'column',
          //   pointWidth: 5,
          //   fillOpacity: 1.0,
          //   threshold: null,
          //   zIndex: 0,
          //   events: {
          //     legendItemClick(e) {
          //       e.preventDefault();
          //     }
          //   },
          //   point: {
          //     events: {
          //       mouseOver: (e) => {
          //         this.chartMouseOverPoint(e);
          //       },
          //       mouseOut: () => {
          //         this.selectedPointTimestamp = 0;
          //       }
          //     }
          //   }
          // }

          // подключение
          {
            name: this.$t('events.clientRFAndEventsConnectedChartSeries'),
            data: [],
            color: 'rgba(146,203,140,0.53)',
            type: 'column',
            pointWidth: 5,
            fillOpacity: 1.0,
            threshold: null,
            zIndex: 0,
            events: {
              legendItemClick(e) {
                e.preventDefault();
              }
            },
            point: {
              events: {
                mouseOver: (e) => {
                  this.chartMouseOverPoint(e);
                },
                mouseOut: () => {
                  this.$refs.highcharts.chart.tooltip.hide(10);
                  this.selectedPointTimestamp = 0;
                }
              }
            }
          },
          // отключение
          {
            name: this.$t('events.clientRFAndEventsDisconnectedChartSeries'),
            data: [],
            color: 'rgba(255,137,150,0.72)',
            type: 'column',
            pointWidth: 5,
            fillOpacity: 1.0,
            threshold: null,
            zIndex: 0,
            events: {
              legendItemClick(e) {
                e.preventDefault();
              }
            },
            point: {
              events: {
                mouseOver: (e) => {
                  this.chartMouseOverPoint(e);
                },
                mouseOut: () => {
                  this.$refs.highcharts.chart.tooltip.hide(10);
                  this.selectedPointTimestamp = 0;
                }
              }
            }
          },
          // авторизация
          {
            name: this.$t('events.clientRFAndEventsAuthorizationChartSeries'),
            data: [],
            color: 'rgba(203,189,88,0.53)',
            type: 'column',
            pointWidth: 5,
            fillOpacity: 1.0,
            threshold: null,
            zIndex: 0,
            events: {
              legendItemClick(e) {
                e.preventDefault();
              }
            },
            point: {
              events: {
                mouseOver: (e) => {
                  this.chartMouseOverPoint(e);
                },
                mouseOut: () => {
                  this.$refs.highcharts.chart.tooltip.hide(10);
                  this.selectedPointTimestamp = 0;
                }
              }
            }
          },
          // dhcp ack
          {
            name: this.$t('events.clientRFAndEventsDhcp_AckChartSeries'),
            data: [],
            color: 'rgba(193,118,203,0.53)',
            type: 'column',
            pointWidth: 5,
            fillOpacity: 1.0,
            threshold: null,
            zIndex: 0,
            events: {
              legendItemClick(e) {
                e.preventDefault();
              }
            },
            point: {
              events: {
                mouseOver: (e) => {
                  this.chartMouseOverPoint(e);
                },
                mouseOut: () => {
                  this.$refs.highcharts.chart.tooltip.hide(10);
                  this.selectedPointTimestamp = 0;
                }
              }
            }
          }
        ]
      },
      limit: 100,
      offset: 0,
      limitRFP: 100,
      offsetRFP: 0,
      delayTimer: null,
      searchValue: '',
      searchValueForRFP: '',
      searchSpinner: '',
      totalResult: false,
      canLoadMore: true,
      canLoadMoreRFP: true,
      isVideoModalOpened: false,
      enableSaveChanges: false,
      showColumnSwitcher: false,
      filters: {
        typeFilter: '',
        statusFilter: 'CONNECTED',
        frequencyFilter: '',
        query: '',
        authorizedFilter: '',
        healthStatusFilter: ''
      },
      filtersRFP: {
        query: ''
      },
      updatedClient: {
        data: {
          description: '',
          rtsp_stream: 'rtsp_stream'
        },
        Type: ''
      },
      clientAuthorization: {
        username: 'Username',
        ua_type: 'Type',
        ua_device: 'Device',
        os: 'OS',
        os_version: 'OS version',
        useragent: 'Useragent'
      }
    };
  },
  computed: {
    eventsCounterClientConnected() {
      try {
        return this.clientEventsDataSeparatedByEventType.CLIENT_CONNECTED.length;
      } catch (e) {
        return 0;
      }
    },
    eventsCounterClientDisconnected() {
      try {
        return this.clientEventsDataSeparatedByEventType.CLIENT_DISCONNECTED.length;
      } catch (e) {
        return 0;
      }
    },
    eventsCounterClientAuthorization() {
      try {
        return this.clientEventsDataSeparatedByEventType.CLIENT_AUTHORIZATION.length;
      } catch (e) {
        return 0;
      }
    },
    eventsCounterClientDHCP_ACK() {
      try {
        return this.clientEventsDataSeparatedByEventType.DHCP_ACK.length;
      } catch (e) {
        return 0;
      }
    },
    isClientRFRemainingDataLoading() {
      return this.$store.state.loadingClientRFRemainingData;
    },
    clientRFDataByTimestamp() {
      const clientRFDataByTimestamp = {};
      for (const item of this.clientRFData) {
        clientRFDataByTimestamp[item.ts] = item.rf;
      }
      return clientRFDataByTimestamp;
    },

    clientEventsByTimestamp() {
      const clientEventsDataByTimestamp = {};
      for (const item of this.clientEventsData) {
        if (Object.prototype.hasOwnProperty.call(clientEventsDataByTimestamp, item.timestamp)) {
          clientEventsDataByTimestamp[item.timestamp].push(item);
        } else {
          clientEventsDataByTimestamp[item.timestamp] = [item];
        }
        // clientEventsDataByTimestamp[item.timestamp] = item;
      }
      // console.log(clientEventsDataByTimestamp)
      return clientEventsDataByTimestamp;
    },

    wifiRateInfoForSelectedPoint() {
      const timestamp = this.selectedPointTimestamp;
      if (this.clientRFDataByTimestamp[timestamp]) {
        const selectedPointRFData = this.clientRFDataByTimestamp[timestamp];
        const Info = this.formatWifiRate(selectedPointRFData);
        return Info;
      }
      return null;
    },
    eventInfoForSelectedPoint() {
      const result = [];
      const timestamp = this.selectedPointTimestamp;
      if (Object.prototype.hasOwnProperty.call(this.clientEventsByTimestamp, timestamp)) {
        const selectedPointEventData = this.clientEventsByTimestamp[timestamp];
        // const Info = selectedPointEventData;
        for (const item of selectedPointEventData) {
          result.push(item);
        }
        // console.log(result)
        return result;
      }
      return null;
    },
    eventTypeForSelectedPoint() {
      try {
        if (
          this.eventInfoForSelectedPoint !== null &&
          Object.prototype.hasOwnProperty.call(this.eventInfoForSelectedPoint, 'type')
        ) {
          return this.eventInfoForSelectedPoint.type;
        }
        return null;
      } catch (e) {
        return null;
      }
    },
    chartData() {
      let chartData = {};
      chartData = JSON.parse(JSON.stringify(this.chartSettings));
      chartData.tooltip.formatter = this.createChartToolbarFormatterWithLocalization();
      chartData.legend.labelFormatter = this.createChartLegendLabelFormatterWithLocalization();
      chartData.series[0].point = this.chartSettings.series[0].point;
      chartData.series[1].point = this.chartSettings.series[1].point;
      chartData.series[2].point = this.chartSettings.series[2].point;
      chartData.series[3].point = this.chartSettings.series[3].point;
      chartData.series[4].point = this.chartSettings.series[4].point;
      chartData.series[5].point = this.chartSettings.series[5].point;
      chartData.series[0].events = this.chartSettings.series[0].events;
      chartData.series[1].events = this.chartSettings.series[1].events;
      chartData.series[2].events = this.chartSettings.series[2].events;
      chartData.series[3].events = this.chartSettings.series[3].events;
      chartData.series[4].events = this.chartSettings.series[4].events;
      chartData.series[5].events = this.chartSettings.series[5].events;
      chartData.series[0].data = [];
      chartData.series[1].data = [];
      chartData.series[2].data = [];
      chartData.series[3].data = [];
      chartData.series[4].data = [];
      chartData.series[5].data = [];
      // this.clientRFDataByTimestamp = {};
      for (const item of this.clientRFData) {
        const dateTime = item.ts * 1000;
        const { signal } = item.rf;
        const { noise } = item.rf;
        chartData.series[0].data.push([dateTime, signal]);
        chartData.series[1].data.push([dateTime, noise]);
      }
      // for (const item of this.clientEventsData) {
      //   const dateTime = item.timestamp * 1000;
      //   chartData.series[2].data.push({ x: dateTime, y: 0, event: item });
      // }
      for (const item of this.clientEventsDataSeparatedByEventType.CLIENT_CONNECTED) {
        const dateTime = item.timestamp * 1000;
        chartData.series[2].data.push({ x: dateTime, y: 0, event: item });
      }
      for (const item of this.clientEventsDataSeparatedByEventType.CLIENT_DISCONNECTED) {
        const dateTime = item.timestamp * 1000;
        chartData.series[3].data.push({ x: dateTime, y: 0, event: item });
      }
      for (const item of this.clientEventsDataSeparatedByEventType.CLIENT_AUTHORIZATION) {
        const dateTime = item.timestamp * 1000;
        chartData.series[4].data.push({ x: dateTime, y: 0, event: item });
      }
      for (const item of this.clientEventsDataSeparatedByEventType.DHCP_ACK) {
        const dateTime = item.timestamp * 1000;
        chartData.series[5].data.push({ x: dateTime, y: 0, event: item });
      }
      return chartData;
    },
    isClientRFLoading() {
      return this.$store.state.loadingclientRF;
    },
    isClientEventsLoading() {
      return this.$store.state.loadingEventsForCilent;
    },
    isItclientRF() {
      return this.$route.name === 'ClientRF';
    },
    editVisible() {
      return typeof this.$store.state.editClientIndex === 'string' && this.$store.state.editClientIndex.length;
    },
    editVisibleRFP() {
      return (
        typeof this.$store.state.editClientForRFPIndex === 'string' && this.$store.state.editClientForRFPIndex.length
      );
    },
    clientsList() {
      return this.$store.state.clientsList;
    },
    clientsRFPList() {
      return this.$store.state.clientsRFPList;
    },
    isLoadingClientsRFPList() {
      return this.$store.state.loadingClientsRFP;
    },
    clientsRFPListByClientID() {
      const result = {};
      if (this.clientsRFPList && this.clientsRFPList.length > 0) {
        for (const client of this.clientsRFPList) {
          result[client.mac] = client;
        }
      }
      return result;
    },
    clientSelectedMac() {
      return this.$store.state.editClientMac;
    },
    clientSelectedForRFPMac() {
      return this.$store.state.editClientForRFPMac;
    },
    isLoadingCLients() {
      return this.$store.state.loadingClients;
    },
    isDisable() {
      return this.$store.state.userData.role == 'operator';
    },
    clientEditCurrent() {
      const { clientsList } = this.$store.state;
      let currentClient;
      clientsList.forEach((client) => {
        if (client.mac == this.$store.state.editClientMac) {
          currentClient = client;
        }
      });
      if (currentClient.Type === 'other') {
        currentClient.data.rtsp_stream = '';
      }
      this.updatedClient = JSON.parse(JSON.stringify(currentClient));
      return currentClient;
    },
    clientEditCurrentForRFP() {
      const { clientsRFPList } = this.$store.state;
      let currentClient;
      clientsRFPList.forEach((client) => {
        if (client.mac == this.$store.state.editClientForRFPMac) {
          currentClient = client;
        }
      });
      if (currentClient.Type === 'other') {
        currentClient.data.rtsp_stream = '';
      }
      // this.updatedClient = JSON.parse(JSON.stringify(currentClient));
      return currentClient;
    },
    tableSettings() {
      return defaultTableSettings.clientRF;
    },
    tableSettingsRFP() {
      return defaultTableSettings.clientRFP;
    },
    clientInterval() {
      return this.$store.state.intervals.client;
    }
  },
  // watch: {
  //   currentTab(val) {
  //     this.disableEditMode();
  //     this.disableEditModeForRFP();
  //     this.selectedPointTimestamp = 0;
  //     this.clientRFData = [];
  //   },
  //   limitRFP(newValue, oldValue) {
  //     if (!parseInt(newValue) || parseInt(newValue) <= 0) {
  //       // console.log('недопустимо!')
  //       this.limitRFP = oldValue;
  //     }
  //   }
  // },
  methods: {
    getCorrectDataValue(value, key) {
      let correctValue = '';
      if (key === 'cpe_id') {
        correctValue = commonService.cpeObjbyId(value) ? commonService.cpeObjbyId(value).name : value;
        return correctValue;
      }
      if (key === 'wlan_id') {
        correctValue = commonService.wlanObjbyId(value) ? commonService.wlanObjbyId(value).ssid : value;
        return correctValue;
      }

      correctValue = value;
      return correctValue;
    },
    getCorrectKey(key) {
      const correctKey =
        key === 'cpe_id' ?
          'CPE' :
          key === 'wlan_id' ?
            'WLAN' :
            key === 'radio_id' ?
              'radio' :
              key === 'freq' ?
                'frequency' :
                key === 'session_id' ?
                  'session' :
                  key === 'description' ?
                    'description' :
                    key === 'version' ?
                      'version' :
                      key === 'commit' ?
                        'commit' :
                        key === 'build' ?
                          'build' :
                          key === 'url' ?
                            'url' :
                            key === 'available_md5' ?
                              'available MD5' :
                              key === 'current_md5' ?
                                'current MD5' :
                                key === 'error' ?
                                  'error' :
                                  key === 'event_type' ?
                                    'event type' :
                                    key === 'event_name' ?
                                      'event name' :
                                      key === 'state' ?
                                        'state' :
                                        `${key}`;
      return correctKey;
    },
    eventStatuslWithI18NAsString(status) {
      // console.log(this.$t(`events.filter${level.toUpperCase()}`))
      // если находим для такого значения уровня события перевод - возвращем его, если нет возвращаем оригинал
      if (this.$t(`events.filter${status.toUpperCase()}`) !== `events.filter${status.toUpperCase()}`) {
        return this.$t(`events.filter${status.toUpperCase()}`);
      }
      return status;
    },
    createChartLegendLabelFormatterWithLocalization() {
      // готовим данные для  для прокидывания внутрь возвращаемой функции для отрисовки лейблов на легенде графика через замыкание
      // значения счетчиков
      const { eventsCounterClientConnected } = this;
      const { eventsCounterClientDisconnected } = this;
      const { eventsCounterClientAuthorization } = this;
      const { eventsCounterClientDHCP_ACK } = this;
      // название серий данных
      const connectedSeriesName = this.$t('events.clientRFAndEventsConnectedChartSeries');
      const disconnectedSeriesName = this.$t('events.clientRFAndEventsDisconnectedChartSeries');
      const authorizationSeriesName = this.$t('events.clientRFAndEventsAuthorizationChartSeries');
      const dhcp_AckSeriesName = this.$t('events.clientRFAndEventsDhcp_AckChartSeries');

      // const eventSeriesNames = [connectedSeriesName, disconnectedSeriesName, authorizationSeriesName, dhcp_AckSeriesName];

      return function () {
        try {
          // console.log(this);
          // let counter = 0;
          // counter = this.xData.length;

          if (this.name === `${connectedSeriesName}`) {
            return `${this.name}: ` + `${eventsCounterClientConnected}` + '';
          }
          if (this.name === `${disconnectedSeriesName}`) {
            return `${this.name}: ` + `${eventsCounterClientDisconnected}` + '';
          }
          if (this.name === `${authorizationSeriesName}`) {
            return `${this.name}: ` + `${eventsCounterClientAuthorization}` + '';
          }
          if (this.name === `${dhcp_AckSeriesName}`) {
            return `${this.name}: ` + `${eventsCounterClientDHCP_ACK}` + '';
          }
          // return this.name + ' (' + counter + ') ';
          return this.name;
        } catch (e) {
          return this.name;
        }
      };
    },
    createChartToolbarFormatterWithLocalization() {
      const ctx = this;
      if (this.$i18n.locale === 'en') {
        return function () {
          try {
            // console.log(this)
            const eventSeriesNames = [
              `${ctx.$t('events.clientRFAndEventsConnectedChartSeries')}`,
              `${ctx.$t('events.clientRFAndEventsDisconnectedChartSeries')}`,
              `${ctx.$t('events.clientRFAndEventsAuthorizationChartSeries')}`,
              `${ctx.$t('events.clientRFAndEventsDhcp_AckChartSeries')}`
            ];
            const dateTimeToTooltip = moment(this.x).format(' D MMM YYYY, H:mm:ss ');
            if (eventSeriesNames.includes(this.points[0].series.name)) {
              // случай если в точке нет данных о Сигнале и Шуме, только события
              const pointsForOutput = [];
              for (const point of this.points) {
                pointsForOutput.push(
                  `${ctx.eventStatuslWithI18NAsString(point.point.event.type.split('_').splice(1).join(' '))} ${ctx.$t(
                    'events.clientRFAndEventsClientCaption'
                  )}<br>`
                );
              }

              return `<b>${dateTimeToTooltip}</b><br>` + `${pointsForOutput.join(' ')}`;
            }
            if (typeof this.points[2] === 'object' && eventSeriesNames.includes(this.points[2].series.name)) {
              // случай если в точке есть и данные о сигнале шуме и данные о событии
              const pointsForOutput = [];
              for (let i = 2; i < this.points.length; i++) {
                pointsForOutput.push(
                  `<b>Event:</b> ${ctx.eventStatuslWithI18NAsString(
                    this.points[i].point.event.type.split('_').splice(1).join(' ')
                  )} ${ctx.$t('events.clientRFAndEventsClientCaption')}<br>`
                );
              }
              return (
                `<b>${dateTimeToTooltip}</b><br>` +
                `${pointsForOutput.join(' ')}` +
                '<p>     </p><br>' +
                `<b>Signal:</b> ${this.points[0].y} dBm <br>` +
                `<b>Noise:</b> ${this.points[1].y} dBm <br><br><hr>` +
                `<b>SNR:</b> ${this.points[0].y - this.points[1].y} dBm`
              );
            }
            return (
              // случай если в точке есть только данные о сигнале и шуме, но нет данных о событии
              `<b>${dateTimeToTooltip}</b><br>` +
              `<b>Signal:</b> ${this.points[0].y} dBm <br>` +
              `<b>Noise:</b> ${this.points[1].y} dBm <br><br><hr>` +
              `<b>SNR:</b> ${this.points[0].y - this.points[1].y} dBm`
            );
          } catch (e) {
            const dateTimeToTooltip = moment(this.x).format(' D MMM YYYY, H:mm:ss ');
            return (
              `<b>${dateTimeToTooltip}</b><br>` +
              `<b>Signal:</b> ${this.points[0].y} dBm <br>` +
              `<b>Noise:</b> ${this.points[1].y} dBm <br><br><hr>` +
              `<b>SNR:</b> ${this.points[0].y - this.points[1].y} dBm`
            );
          }
        };
      }
      if (this.$i18n.locale === 'ru') {
        return function () {
          try {
            // console.log(this)
            const eventSeriesNames = [
              `${ctx.$t('events.clientRFAndEventsConnectedChartSeries')}`,
              `${ctx.$t('events.clientRFAndEventsDisconnectedChartSeries')}`,
              `${ctx.$t('events.clientRFAndEventsAuthorizationChartSeries')}`,
              `${ctx.$t('events.clientRFAndEventsDhcp_AckChartSeries')}`
            ];
            const dateTimeToTooltip = moment(this.x).format(' D MMM YYYY, H:mm:ss ');
            if (eventSeriesNames.includes(this.points[0].series.name)) {
              // случай если в точке нет данных о Сигнале и Шуме, только события
              const pointsForOutput = [];
              for (const point of this.points) {
                pointsForOutput.push(
                  `${ctx.eventStatuslWithI18NAsString(point.point.event.type.split('_').splice(1).join(' '))} ${ctx.$t(
                    'events.clientRFAndEventsClientCaption'
                  )}<br>`
                );
              }

              return `<b>${dateTimeToTooltip}</b><br>` + `${pointsForOutput.join(' ')}`;
            }
            if (typeof this.points[2] === 'object' && eventSeriesNames.includes(this.points[2].series.name)) {
              // случай если в точке есть и данные о сигнале шуме и данные о событии
              const pointsForOutput = [];
              for (let i = 2; i < this.points.length; i++) {
                pointsForOutput.push(
                  `<b>Событие:</b> ${ctx.eventStatuslWithI18NAsString(
                    this.points[i].point.event.type.split('_').splice(1).join(' ')
                  )} ${ctx.$t('events.clientRFAndEventsClientCaption')}<br>`
                );
              }
              return (
                `<b>${dateTimeToTooltip}</b><br>` +
                `${pointsForOutput.join(' ')}` +
                '<p>     </p><br>' +
                `<b>Сигнал:</b> ${this.points[0].y} dBm <br>` +
                `<b>Шум:</b> ${this.points[1].y} dBm <br><br><hr>` +
                `<b>SNR:</b> ${this.points[0].y - this.points[1].y} dBm`
              );
            }
            return (
              // случай если в точке есть ттолько данные о сигнале и шуме, но нет данных о событии
              `<b>${dateTimeToTooltip}</b><br>` +
              `<b>Сигнал:</b> ${this.points[0].y} dBm <br>` +
              `<b>Шум:</b> ${this.points[1].y} dBm <br><br><hr>` +
              `<b>SNR:</b> ${this.points[0].y - this.points[1].y} dBm`
            );
          } catch (e) {
            const dateTimeToTooltip = moment(this.x).format(' D MMM YYYY, H:mm:ss ');
            return (
              `<b>${dateTimeToTooltip}</b><br>` +
              `<b>Сигнал:</b> ${this.points[0].y} dBm <br>` +
              `<b>Шум:</b> ${this.points[1].y} dBm <br><br><hr>` +
              `<b>SNR:</b> ${this.points[0].y - this.points[1].y} dBm`
            );
          }
        };
      }
      return null;
    },
    chartMouseOverPoint(event) {
      // console.log(event.target.x / 1000);
      // console.log(event.target)
      this.selectedPointTimestamp = event.target.x / 1000;
    },
    // chartMouseOverEventPoint(event) {
    //   // console.log(event)
    //   this.selectedEventPointTimestamp = event.target.x / 1000;
    // },
    formatWifiRate(clientRfObject) {
      const rf = clientRfObject;
      const formatedWifiRate = {};
      let rxStr = `${rf.rx_rate / 1000} Mbit/s, ${rf.rx_mhz} MHz`;

      if (rf.rx_ht || rf.rx_vht) {
        if (rf.rx_vht) rxStr += `, VHT-MCS ${rf.rx_mcs}`;
        if (rf.rx_nss) rxStr += `, VHT-NSS ${rf.rx_nss}`;
        if (rf.rx_ht) rxStr += `, MCS ${rf.rx_mcs}`;
        if (rf.rx_short_gi) rxStr += ', Short GI';
      }

      let txStr = `${rf.tx_rate / 1000} Mbit/s, ${rf.tx_mhz} MHz`;
      if (rf.tx_ht || rf.tx_vht) {
        if (rf.tx_vht) txStr += `, VHT-MCS ${rf.tx_mcs}`;
        if (rf.tx_nss) txStr += `, VHT-NSS ${rf.tx_nss}`;
        if (rf.tx_ht) txStr += `, MCS ${rf.tx_mcs}`;
        if (rf.tx_short_gi) txStr += ', Short GI';
      }
      let throughputStr;
      if (clientRfObject.expected_throughput === 0) {
        throughputStr = 0;
      } else {
        throughputStr = `${clientRfObject.expected_throughput / 1000} Mbit/s`;
      }
      formatedWifiRate.rx = rxStr;
      formatedWifiRate.tx = txStr;
      formatedWifiRate.throughput = throughputStr;
      return formatedWifiRate;
    },

    // getClientRf() {
    //   this.$store.commit('toggleLoadingClientRF');
    //   // const mac = this.$store.state.editClientMac; - так было раньше
    //   // теперь прокидываем mac_addr девайса (из /api/clients)
    //   // чтобы обеспечить маскировку мак адресов у некоторых клиентов
    //   // пользователю приходит или mac или макозаментель в этом поле.
    //   // В зависимости от того есть ли у пользователя флажок mac_mask типа boolean
    //   // const mac = this.$store.state.editClientMacAddr;
    //   // плюс теперь еще смотрим какой таб открыт, Table или Rating чтобы запросить с выбранным маком
    //   let mac;
    //   // if (this.currentTab === 0) {
    //   //   mac = this.$store.state.editClientMacAddr;
    //   // }
    //   // if (this.currentTab === 1) {
    //   //   mac = this.$store.state.editClientForRFPMacAddr;
    //   // }
    //   mac = this.clientMacAddr;
    //   // console.log(mac);
    //   let startDate = (this.drPickerDateForRF.startDate / 1000).toFixed(0);
    //   let endDate = (this.drPickerDateForRF.endDate / 1000).toFixed(0);
    //   startDate = parseInt(startDate, 10);
    //   endDate = parseInt(endDate, 10);
    //   // чистим выбранную точку на графике
    //   this.selectedPointTimestamp = 0;
    //   // чистим массив со значениями RF
    //   this.clientRFData = [];
    //   Vue.axios
    //     .post(`${window.RESTAPI || ''}/api/client/rf`, {
    //       action: 'R',
    //       sort_by: { field: 'ts', order: 1 },
    //       timebounds: { start: startDate, stop: endDate },
    //       q: { mac: `${mac}` }
    //     })
    //     .then(
    //       (response) => {
    //         if (response.data.status === 'success') {
    //           if (response.data.data.itemslist[0]) {
    //             for (const item of response.data.data.itemslist) {
    //               this.clientRFData.push(item);
    //             }
    //           }
    //           this.$store.commit('toggleLoadingClientRF');
    //         } else if (response.data.status === 'error') {
    //           this.$store.commit('toggleLoadingClientRF');
    //           VueNotifications.error({ message: response.data.description });
    //         }
    //       },
    //       (err) => {
    //         this.$store.commit('toggleLoadingClientRF');
    //         VueNotifications.error({ message: err });
    //       }
    //     );
    // },

    getClientRf() {
      this.$store.commit('toggleLoadingClientRF');
      // const mac = this.$store.state.editClientMac; - так было раньше
      // теперь прокидываем mac_addr девайса (из /api/clients)
      // чтобы обеспечить маскировку мак адресов у некоторых клиентов
      // пользователю приходит или mac или макозаментель в этом поле.
      // В зависимости от того есть ли у пользователя флажок mac_mask типа boolean
      // const mac = this.$store.state.editClientMacAddr;
      // плюс теперь еще смотрим какой таб открыт, Table или Rating чтобы запросить с выбранным маком
      let mac;
      // if (this.currentTab === 0) {
      //   mac = this.$store.state.editClientMacAddr;
      // }
      // if (this.currentTab === 1) {
      //   mac = this.$store.state.editClientForRFPMacAddr;
      // }
      mac = this.clientMacAddr;
      // console.log(mac);
      let startDate = (this.drPickerDateForRF.startDate / 1000).toFixed(0);
      let endDate = (this.drPickerDateForRF.endDate / 1000).toFixed(0);
      startDate = parseInt(startDate, 10);
      endDate = parseInt(endDate, 10);
      // чистим выбранную точку на графике
      this.selectedPointTimestamp = 0;
      // чистим массив со значениями RF
      this.clientRFData = [];

      // Vue.axios
      // .post(`${window.RESTAPI || ''}/api/client/rf`, {
      //   action: 'R',
      //   sort_by: { field: 'ts', order: 1 },
      //   timebounds: { start: startDate, stop: endDate },
      //   q: { mac: `${mac}` }
      // })
      const paramsForRequest = {
        action: 'R',
        location: this.$store.getters.getDefaultLocationForRequests(this), with_childs: this.$store.getters.getDefaultWithChildsForRequests(this),
        sort_by: { field: 'ts', order: 1 },
        timebounds: { start: startDate, stop: endDate },
        q: { mac: `${mac}` }
      };
      Vue.axios.post(`${window.RESTAPI || ''}/api/client/rf`, paramsForRequest).then(
        (response) => {
          if (response.data.status === 'success') {
            let loaded;
            let total;
            const dataFromResponse = [];
            if (response.data.data.itemslist[0]) {
              // console.log(response);
              loaded = response.data.data.count;
              total = response.data.data.total;
              for (const item of response.data.data.itemslist) {
                dataFromResponse.push(item);
              }
            }
            this.$store.commit('toggleLoadingClientRF');
            // тут может вызывается функция докачивающая данные. Дело в том что бэк отдает только 2000 записей.
            // И при запросе на день этого хватает, а например на неделю нет.
            // Из ручки rf прилетает не больше 2000 записей,
            // Хотя их фактически может быть больше, например 5000 (count: 2000, total: 5000).
            // Эти первые 2000 и отображались.
            // Теперь нализируем  ответ от первого запроса в rf, подсчитывается сколько еще записей не хватает и есть ли вообще такие
            // И инициируется новый запрос для подтягивания недостающих данных по rf (с помощью limit и offset), если нужно

            if (loaded && total) {
              // console.log('loaded', loaded);
              // console.log('total', total);
              if (loaded < total) {
                const offset = loaded;
                const limit = total - loaded;
                if (limit > 0 && offset > 0) {
                  // console.log('limit', limit);
                  // console.log('offset', offset);
                  this.getClientRfRemainingData(limit, offset, dataFromResponse, paramsForRequest);
                } else {
                  this.clientRFData = dataFromResponse;
                }
              } else {
                this.clientRFData = dataFromResponse;
              }
            } else {
              this.clientRFData = dataFromResponse;
            }
          } else if (response.data.status === 'error') {
            this.$store.commit('toggleLoadingClientRF');
            VueNotifications.error({ message: response.data.description });
          }
        },
        (err) => {
          this.$store.commit('toggleLoadingClientRF');
          VueNotifications.error({ message: err });
        }
      );
    },
    getClientRfRemainingData(limit, offset, dataFromFirstReq, paramsForRequestFromFirstRequest) {
      // console.log('get remaining data')
      // используется для догрузки данных из ручки rf.
      // Подробнее в комментах к функции getClientRf
      if (!limit || !offset) {
        this.clientRFData = dataFromFirstReq;
        return;
      }
      this.$store.commit('loadingClientRFRemainingDataBegin');
      const praramsForRequest = JSON.parse(JSON.stringify(paramsForRequestFromFirstRequest));
      praramsForRequest.page = { limit, offset };

      // чистим массив со значениями RF
      this.clientRFData = [];
      Vue.axios.post(`${window.RESTAPI || ''}/api/client/rf`, praramsForRequest).then(
        (response) => {
          if (response.data.status === 'success') {
            if (response.data.data.itemslist[0]) {
              for (const item of response.data.data.itemslist) {
                dataFromFirstReq.push(item);
              }
            }
            this.clientRFData = dataFromFirstReq;
            this.$store.commit('loadingClientRFRemainingDataEnd');
          } else if (response.data.status === 'error') {
            this.clientRFData = dataFromFirstReq;
            this.$store.commit('loadingClientRFRemainingDataEnd');
            VueNotifications.error({ message: response.data.description });
          }
        },
        (err) => {
          this.clientRFData = dataFromFirstReq;
          this.$store.commit('loadingClientRFRemainingDataEnd');
          VueNotifications.error({ message: err });
        }
      );
    },

    getClientEvents() {
      this.$store.commit('loadingEventsForClientBegin');
      this.selectedPointTimestamp = 0;
      let mac;

      mac = this.clientMacAddr;
      // console.log(mac);
      let startDate = (this.drPickerDateForRF.startDate / 1000).toFixed(0);
      let endDate = (this.drPickerDateForRF.endDate / 1000).toFixed(0);
      startDate = parseInt(startDate, 10);
      endDate = parseInt(endDate, 10);

      this.clientEventsData = [];
      this.clientEventsDataSeparatedByEventType = {
        CLIENT_CONNECTED: [],
        CLIENT_DISCONNECTED: [],
        CLIENT_AUTHORIZATION: [],
        DHCP_ACK: []
      };
      const query = {
        action: 'R',
        location: this.$store.getters.getDefaultLocationForRequests(this), with_childs: this.$store.getters.getDefaultWithChildsForRequests(this),
        sort_by: { field: 'timestamp', order: 1 },
        q: {
          type: { $in: ['CLIENT_CONNECTED', 'CLIENT_DISCONNECTED', 'CLIENT_AUTHORIZATION', 'DHCP_ACK'] },
          subject_id: { $in: [mac, mac.toLowerCase(), mac.toUpperCase()] }
        },
        timebounds: {
          start: startDate,
          stop: endDate
        }
      };
      Vue.axios.post(`${window.RESTAPI || ''}/api/events`, query).then(
        (response) => {
          if (response.data.status === 'success') {
            if (response.data.data.itemslist[0]) {
              for (const item of response.data.data.itemslist) {
                this.clientEventsData.push(item);
                if (item.type === 'CLIENT_CONNECTED') {
                  this.clientEventsDataSeparatedByEventType.CLIENT_CONNECTED.push(item);
                }
                if (item.type === 'CLIENT_DISCONNECTED') {
                  this.clientEventsDataSeparatedByEventType.CLIENT_DISCONNECTED.push(item);
                }
                if (item.type === 'CLIENT_AUTHORIZATION') {
                  this.clientEventsDataSeparatedByEventType.CLIENT_AUTHORIZATION.push(item);
                }
                if (item.type === 'DHCP_ACK') {
                  this.clientEventsDataSeparatedByEventType.DHCP_ACK.push(item);
                }
              }
            }
            this.$store.commit('loadingEventsForClientEnd');
          } else if (response.data.status === 'error') {
            this.$store.commit('loadingEventsForClientEnd');
            VueNotifications.error({ message: response.data.description });
          }
        },
        (err) => {
          this.$store.commit('loadingEventsForClientEnd');
          VueNotifications.error({ message: err });
        }
      );
    },
    datePickerChangedDate(e) {
      this.drPickerDateForRF.startDate = e.startDate;
      this.drPickerDateForRF.endDate = e.endDate;
      this.getClientRf();
      this.getClientEvents();
    },
    datePickerChangedDateForRFP(e) {
      this.drPickerDateForRFP.startDate = e.startDate;
      this.drPickerDateForRFP.endDate = e.endDate;
      // еще ставим дату для datepicker который запрашивает данные RF для графика
      this.drPickerDateForRF.startDate = e.startDate;
      this.drPickerDateForRF.endDate = e.endDate;
      this.datePickerInputHandlerForRFP();
    },
    toggleEdit(e) {
      if (e.target.dataset.action === 'show-video') {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      const { mac } = selectedNode.dataset;
      const { mac_addr } = selectedNode.dataset;
      // чтобы в датапикере перерисовалась дата скрываем его, а потом показываем
      this.datepickerVisible = false;
      // this.$set(this.drPickerDateForRF, 'startDate', moment().startOf('hour').valueOf());
      // this.$set(this.drPickerDateForRF, 'endDate', moment().endOf('hour').valueOf());
      this.$store.commit('setClientEditIndex', index);
      this.$store.commit('setClientEditMac', mac);
      this.$store.commit('setClientEditMacAddr', mac_addr);
      this.$nextTick(() => {
        // чтобы в датапикере перерисовалась дата
        this.datepickerVisible = true;
      });
      // this.getClientRfLast();
      this.getClientRf();
    },
    toggleEditRFP(e) {
      if (e.target.dataset.action === 'show-video') {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      const { mac } = selectedNode.dataset;
      const { mac_addr } = selectedNode.dataset;
      // чтобы в датапикере перерисовалась дата скрываем его, а потом показываем
      this.datepickerVisible = false;
      // this.$set(this.drPickerDateForRF, 'startDate', moment().startOf('hour').valueOf());
      // this.$set(this.drPickerDateForRF, 'endDate', moment().endOf('hour').valueOf());
      this.$store.commit('setClientEditForRFPIndex', index);
      this.$store.commit('setClientEditForRFPMac', mac);
      this.$store.commit('setClientEditForRFPMacAddr', mac_addr);
      this.$nextTick(() => {
        // чтобы в датапикере перерисовалась дата
        this.datepickerVisible = true;
      });
      // this.getClientRfLast();
      this.getClientRf();
    },
    searchInputHandler(value) {
      this.disableEditModeFromSearchInputHandler();

      this.searchValue = value;
      this.searchSpinner = true;
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        // this.refreshEvents();
        clientService.refreshClients(this, 'spinner');
      }, 1000);
    },
    searchInputHandlerForRFP(value) {
      this.disableEditModeFromSearchInputHandlerForRFP();

      this.searchValueForRFP = value;
      this.searchSpinner = true;
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        // this.refreshEvents();
        clientService.refreshClientsForRFP(this, 'spinner');
      }, 1000);
    },
    limitInputHandlerForRFP() {
      this.disableEditModeFromSearchInputHandlerForRFP();

      // this.searchValueForRFP = value;
      this.searchSpinner = true;
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        // this.refreshEvents();
        clientService.refreshClientsForRFP(this, 'spinner');
      }, 1000);
    },
    datePickerInputHandlerForRFP() {
      this.disableEditModeFromSearchInputHandlerForRFP();

      // this.searchValueForRFP = value;
      this.searchSpinner = true;
      clientService.refreshClientsForRFP(this, 'spinner');
    },
    refreshClientsListQuery() {
      this.canLoadMore = true;
      this.offset = 0;
      clientService.refreshClients(this, 'spinner');
    },
    refreshClientsRFPListQuery() {
      this.canLoadMoreRFP = true;
      this.offsetRFP = 0;
      clientService.refreshClientsRFP(this, 'spinner');
    },
    showAllClients() {
      this.filters.statusFilter = '';
      this.canLoadMore = true;
      clientService.refreshClients(this, 'spinner');
    },
    showAllRFPClients() {
      // this.filtersRFP.statusFilter = '';
      this.canLoadMoreRFP = true;
      clientService.refreshClientsRFP(this, 'spinner');
    },
    loadMore() {
      this.offset++;
      clientService.getPaginatedClients(this, this.limit, this.offset);
    },
    loadMoreRFP() {
      this.offsetRFP++;
      clientService.getPaginatedClientsRFP(this, this.limitRFP, this.offsetRFP);
    },
    toggleColumnSettings() {
      this.showColumnSwitcher = !this.showColumnSwitcher;
    },
    toggleTableColumn(value, key) {
      this.$store.commit('setTableSettings', {
        tableName: 'client',
        key,
        value
      });
    },
    filterItems(items) {
      let filteredClients = items;
      if (filteredClients) {
        filteredClients = Filters.clientTypeFilters(filteredClients, this.filters.typeFilter);
        filteredClients = Filters.clientFrequencyFilters(filteredClients, this.filters.frequencyFilter);
        filteredClients = Filters.clientSearchQuery(filteredClients, this.filters.query);
      }
      this.filteredClients = filteredClients;

      return filteredClients;
    },
    filterItemsRFP(items) {
      const filteredClients = items;
      // if (filteredClients) {
      //   filteredClients = Filters.clientTypeFilters(filteredClients, this.filters.typeFilter);
      //   filteredClients = Filters.clientFrequencyFilters(filteredClients, this.filters.frequencyFilter);
      //   filteredClients = Filters.clientSearchQuery(filteredClients, this.filters.query);
      // }
      this.filteredClients = filteredClients;
      return filteredClients;
    },
    setFilter(filterName, value) {
      this.disableEditMode();
      if (this.filters[filterName] !== value) {
        this.filters[filterName] = value;
      } else {
        this.filters[filterName] = '';
      }
      if (filterName === 'typeFilter' && value === 'wired') {
        this.filters.frequencyFilter = '';
      }
      this.refreshClientsListQuery();
    },
    disableEditMode() {
      this.$store.commit('setClientEditIndex', null);
      this.$store.commit('setClientEditMac', null);
      this.$store.commit('setClientEditMacAddr', null);
    },
    disableEditModeForRFP() {
      this.$store.commit('setClientEditForRFPIndex', null);
      this.$store.commit('setClientEditForRFPMac', null);
      this.$store.commit('setClientEditForRFPMacAddr', null);
    },
    cpeNamebyId(id) {
      const cpeNamebyId = commonService.cpeNamebyId(id);
      return cpeNamebyId;
    },
    wlanNamebyId(id) {
      const wlanNamebyId = commonService.wlanNamebyId(id);
      return wlanNamebyId;
    },
    getFormatMac(mac) {
      return helpers.getFormatMac(mac);
    },
    formatToCSV(clients) {
      if (!clients) {
        return [];
      }

      const csvClients = JSON.parse(JSON.stringify(clients));

      return csvClients.map((client) => ({
        Type: client.Type ? client.Type : '',
        MAC: client.mac ? this.getFormatMac(client.mac) : '',
        IP: client.ip ? client.ip : '',
        Authorized: !!client.username,
        Description: client.data.description ? client.data.description : '',
        CPE: client.cpe_id ? this.cpeNamebyId(client.cpe_id) : '',
        Frequency: client.Type !== 'wired' && client.freq ? client.freq : '',
        Channel: client.Type !== 'wired' && client.channel ? client.channel : '',
        RSSI: client.Type !== 'wired' && client.rssi ? client.rssi : '',
        'Ethernet Interface': client.Type === 'wired' && client.radio_id ? client.radio_id : '',
        WLAN: client.Type !== 'wired' && client.wlan_id ? this.wlanNamebyId(client.wlan_id) : ''
      }));
    },
    disableEditModeFromSearchInputHandler() {
      this.$store.commit('setClientEditIndex', null);
      this.$store.commit('setClientEditMac', null);
      this.$store.commit('setClientEditMacAddr', null);
      this.$nextTick(() => {});
    },
    disableEditModeFromSearchInputHandlerForRFP() {
      this.$store.commit('setClientEditForRFPIndex', null);
      this.$store.commit('setClientEditForRFPMac', null);
      this.$store.commit('setClientEditForRFPMacAddr', null);
      this.$nextTick(() => {});
    }
  },
  filters: {
    moment(value) {
      if (value) {
        return moment(value * 1000).format(' D MMM YYYY, H:mm:ss ');
      }
    },
    snrAvgRound(value) {
      if (value.toFixed(3)) {
        return value.toFixed(3);
      }
      return value;
    }
  },
  // created() {
  //   this.$store.commit('setCpesList', false);
  //   this.filters.statusFilter = 'CONNECTED';
  //   this.filters.typeFilter = 'other';
  //   this.$store.commit('setClientsList', false);
  //   this.$store.commit('setClientsRFPList', false);
  //   this.disableEditMode();
  //   this.disableEditModeForRFP();
  //   if (this.$route.params.id) {
  //     this.filters.statusFilter = '';
  //     this.searchValue = this.$route.params.id;
  //     this.searchInputHandler(this.searchValue);
  //   } else {
  //     clientService.getPaginatedClients(this, this.limit, this.offset);
  //     clientService.getPaginatedClientsRFP(this, this.limitRFP, this.offsetRFP);
  //   }
  // }
  created() {
    this.getClientRf();
    this.getClientEvents();
  }
};
</script>

<style lang="scss" scoped>
.client {
  display: flex;
}

.client-list,
.client-edit {
  transition: width 0.2s;
  will-change: transform;
}

.client-list {
  display: inline-block;
  width: 100%;
}

.client-edit {
  display: inline-block;
  width: 0%;
  word-wrap: break-word;
}

.client-edit .card-header {
  border: 0px;
}

.edit-visible .client-list {
  transition: width 0.2s;
  margin-right: 10px;
  min-width: 230px;
  width: 15%;
  will-change: transform;
}

.edit-visible .client-edit {
  transition: width 0.2s;
  min-width: 400px;
  width: 85%;
  will-change: transform;
}

.client-row {
  transition: background 0.15s;
  cursor: pointer;
}

.client-row:hover {
  background: rgba(236, 236, 236, 0.59);
}

.client-row.selected {
  background: #d9e3ec;
}

.client-state {
  display: flex;
  flex-direction: row;

  &--column {
    flex-direction: column;
  }

  &__main {
  }
}

.clients-camera-button {
  cursor: pointer;
  padding-left: 0.5rem;
}
.clients-camera-button:hover i {
  transition: transform 0.2s;
}

.clients-camera-button:hover i {
  transform: scale(1.4);
}

.badge-client {
  position: relative;
  white-space: nowrap;
}

.client-name {
  white-space: nowrap;
  position: relative;
  & i {
    position: absolute;
    display: block;
    width: 15px;
    top: 2px;
    left: -38px;
  }

  & i.fa-mobile {
    /*left: 3px;*/
  }

  & .badge-client {
    margin-left: 40px;
  }
}
</style>

<style lang="scss" scoped>
@media (max-width: 1130px) {
  .client-list {
    min-width: 870px;
  }

  .edit-visible .client-list {
    min-width: 230px;
  }
}

.description-row {
  word-break: break-word;
  overflow: hidden;
}

.client-info {
  display: flex;
}

.client-info-general,
.client-info-authorization {
  flex: 1;
}

.table-column-switcher {
  position: absolute;
  right: 0;
}

.loader-backdrop-clientsRF:after {
  position: absolute;
  top: 30px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.clear-background-and-clear-padding-for-clients-rf {
  margin: 0 !important;
  padding: 0 !important;
  background-color: transparent !important;
  border-color: transparent !important;
}
.client-rf-and-events-under-chart-info {
  width: 200px !important;
  font-size: 0.85em;
}
.client-rf-and-events-under-chart-info-if-more-the-two-events {
  width: 500px !important;
}
.events-info-ul-in-client-rf-and-events {
  list-style-type: none !important;
}
client-rf-and-events-under-chart-info-header1 {
  font-size: 2em !important;
  font-weight: bold;
}
client-rf-and-events-under-chart-info-header2 {
  font-size: 1.7em !important;
  font-weight: bold;
}
.events-counters-under-the-chart {
  position: relative;
  top: -5px;
  font-size: 12px;
  font-weight: bold;
  font-family: 'Lucida Grande', 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
}
</style>
